import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import PostSharing from '../components/PostSharing';
import { persianDate } from '../utils/helper';
import { Helmet } from "react-helmet";

class BlogPost extends Component {
    render() {
        const { siteMetadata } = this.props.data.site;
        const post = this.props.data.markdownRemark;
        const { title, date, slug, description, keyword, image, subtitle, newsletter, about } = post.frontmatter;
        const shamsiDate = persianDate(date);
        return (         
            <Layout newsletter={newsletter} about={about}>
                <Helmet>
                    <title>{`${title} | فیلترشکن‌ها`}</title>
                    <link rel="canonical" href={`https://filtershekanha.com/blog/${slug}`} />
                    <meta name="description" content={`${description || siteMetadata.main.description }`} />
                    <meta name="keywords" content={`${keyword || siteMetadata.main.keyword }`} />
                    <meta name="twitter:title" content={`${title}`} />
                    <meta name="twitter:description" content={`${description}`} />
                    <meta name="twitter:image" content={`${image || siteMetadata.main.cover }`} />
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:url" content={`https://filtershekanha.com/blog/${slug}`} />
                    <meta property="og:url" content={`https://filtershekanha.com/blog/${slug}`} />
                    <meta property="og:image" content={`${image || siteMetadata.main.cover }`} />           
                    <meta property="og:title" content={`${title}`} />
                    <meta property="og:description" content={`${description || siteMetadata.main.description }`} />
                </Helmet> 
                <div className="row my-5 py-md-5"></div>
                <div className="row justify-content-center">
                    <div className="col-md-9 col-12 my-5 text-center">
                        <h3 className="dir-rtl py-2  text-secondary">{ shamsiDate }</h3>
                        <h1 className="dir-rtl py-2 c-a">{ title }</h1>
                        <PostSharing link={this.props.location.href} text={`${title}`} />
                    </div>
                    <div className="col-12 my-2"></div>
                    <div className="col-md-9 col-12 my-3 text-center">
                        <h3 className="dir-rtl py-2 text-right">{ subtitle }</h3>
                    </div>
                    <div className="col-md-9 col-12 text-justify dir-rtl post my-5" dangerouslySetInnerHTML={{ __html: post.html }} />
                </div>
                <div className="row my-4 py-md-4"></div>
            </Layout>
        )
    }
}

export const query = graphql`
    query PostQuery($slug: String!) {
        site {
            siteMetadata {
                main {
                    title
                    description
                    image
                    cover
                    keyword
                }
            }
        }        
        markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            id
            frontmatter {
                title
                subtitle
                slug
                keyword
                image
                description
                date
                newsletter
                about
            }
        }
   }
`

export default BlogPost;