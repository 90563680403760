import React, { Component } from 'react';


class PostSharing extends Component {    
    render() {
        const { text, link } = this.props;
        return (
            <div className="row">
                <div className="s-Box text-center" aria-hidden={true}>

                    <a href={`https://www.facebook.com/sharer/sharer.php?u=${link}`} className="share-icon fb-share" target="_blank" rel="noopener noreferrer" aria-label="به اشتراک گذاشتن در فیسبوک">
                        <span>به اشتراک گذاشتن در فیسبوک</span>
                    </a>
                    
                    <a href={`https://twitter.com/intent/tweet?text=${text} ${link}`} className="share-icon twitter-share" target="_blank" rel="noopener noreferrer" aria-label="به اشتراک گذاشتن در توییتر">
                        <span>به اشتراک گذاشتن در توییتر</span>
                    </a>

                    <a href={`https://telegram.me/share/url?url=${link}&text=${text}`} className="share-icon telegram-share" target="_blank" rel="noopener noreferrer" aria-label="به اشتراک گذاشتن در تلگرام">
                        <span>به اشتراک گذاشتن در تلگرام</span>
                    </a>
                </div>
            </div>
        )
    }
}

export default PostSharing;